import { InjectionToken, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ThemeService } from './theme.service';

export type ActiveThemeProvider = Observable<string>;

export const ACTIVE_THEME = new InjectionToken<ActiveThemeProvider>('Active theme', {
  factory() {
    return inject(ThemeService).theme$;
  },
});

export const ACTIVE_THEME_HEADER_CLASS = new InjectionToken<ActiveThemeProvider>(
  'Active theme header class',
  {
    factory() {
      return inject(ThemeService).headerClass$;
    },
  }
);
