export const THEME_OPTIONS = [
  {
    name: 'Classic',
    value: 'default',
    headerClass: 'font-default text-accent500',
  },
  {
    name: 'NEON CLUB',
    value: 'neon-club',
    headerClass: 'font-neon-club text-accent500 hover:animate-[flicker_1.5s_infinite_alternate]',
  },
  {
    name: 'Purple Haze',
    value: 'purple-haze',
    headerClass: 'font-purple-haze text-accent500',
  },
  {
    name: 'Barbie',
    value: 'barbie',
    headerClass:
      'font-barbie font-medium text-white text-accent-500 [-webkit-text-fill-color:_#fff] [text-shadow:_-1px_-1px_var(--accent-500),_0.2px_0.2px_var(--accent-500),_0.3px_0.3px_var(--accent-500),_0.4px_0.4px_var(--accent-500),_0.5px_0.5px_var(--accent-500),_0.6px_0.6px_var(--accent-500),_0.7px_0.7px_var(--accent-500),_0.8px_0.8px_var(--accent-500),_0.9px_0.9px_var(--accent-500),_1px_1px_var(--accent-500),_1.1px_1.1px_var(--accent-500),_1.2px_1.2px_var(--accent-500),_1.3px_1.3px_var(--accent-500),_1.4px_1.4px_var(--accent-500),_1.5px_1.5px_var(--accent-500),_1.6px_1.6px_var(--accent-500),_1.7px_1.7px_var(--accent-500),_1.8px_1.8px_var(--accent-500),_1.9px_1.9px_var(--accent-500),_2px_2px_var(--accent-500),_2.1px_2.1px_var(--accent-500),_2.2px_2.2px_var(--accent-500),_2.3px_2.3px_var(--accent-500),_2.4px_2.4px_var(--accent-500),_2.5px_2.5px_var(--accent-500),_2.6px_2.6px_var(--accent-500),_2.7px_2.7px_var(--accent-500),_2.8px_2.8px_var(--accent-500),_2.9px_2.9px_var(--accent-500),_3px_3px_var(--accent-500)] ',
  },
  {
    name: 'Pride',
    value: 'pride',
    headerClass:
      'font-pride bg-clip-text text-[transparent] [background-image:_linear-gradient(to_right,var(--group-color1),_var(--group-color2),_var(--group-color3),_var(--group-color4),_var(--group-color5))]',
  },
];
