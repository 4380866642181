import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { THEME_OPTIONS } from './constants';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private themeName = localStorage.getItem('theme') ?? 'default';
  private theme = new BehaviorSubject(this.themeName);
  private headerClass = new BehaviorSubject(this.getHeaderClass());

  public theme$ = this.theme.asObservable();
  public headerClass$ = this.headerClass.asObservable();

  setTheme(theme: string) {
    localStorage.setItem('theme', theme);
    this.theme.next(theme);
    this.headerClass.next(this.getHeaderClass(theme));
  }

  getHeaderClass(theme: string = this.themeName) {
    return (
      THEME_OPTIONS.find(themeItem => themeItem.value === theme)?.headerClass ||
      THEME_OPTIONS[0].headerClass
    );
  }
}
